<template>
  <!-- 高德地图  -->
  <div class="cont">
    <div class="head_NavBar">
      <van-icon name="arrow-left" size="20" @click="back()" />
      <div class="title">实体展场</div>
    </div>
    <van-dropdown-menu>
      <van-dropdown-item
        v-model="dizhiData.type"
        :options="option1"
        @change="LocationFun"
      />
      <van-dropdown-item
        v-model="dizhiData.citycode"
        :options="option2"
        @change="citycodeFun"
      />
    </van-dropdown-menu>
    <div id="bai-du-map">
      <!-- 技术支持和联系方式  -->
    </div>
    <div class="dizhiList" :class="dizhiList ? 'showdizhiList' : ''">
      <div class="title" @click="dizhiList = !dizhiList">
        展开列表
        <span class="icon iconfont">{{
          dizhiList ? "&#xe632;" : "&#xe630;"
        }}</span>
      </div>
      <div class="dizhi_list_cont">
        <ul>
          <li @click="ding(topData)">
            <div class="list_box list_box_arr">
              <div class="list_title">
                <div class="name">{{ topData.brand }}</div>
                <div class="daohang" @click.stop="openMap(topData)">
                  <span class="icon iconfont">&#xe640;</span>导航 >
                </div>
              </div>
              <div class="text">
                {{ topData.address }}
              </div>
            </div>
          </li>
          <li
            v-for="(item, index) in diquList"
            :key="index"
            @click="ding(item)"
          >
            <div class="list_box">
              <div class="list_title">
                <div class="name">{{ item.brand }}</div>
                <div class="daohang" @click.stop="openMap(item)">
                  <span class="icon iconfont">&#xe640;</span>导航 >
                </div>
              </div>
              <div class="text">
                {{ item.address }}
              </div>
            </div>
          </li>
          item
        </ul>
      </div>
    </div>
  </div>
</template>
 
 <script>
import wx from "weixin-js-sdk";
import AMapLoader from "@amap/amap-jsapi-loader";
import $ from 'jquery'
import { DropdownMenu, DropdownItem, Icon, Dialog } from "vant";
window._AMapSecurityConfig = {
  // 设置安全密钥
  securityJsCode: "d7c78951aef7b114a35b47e18ea22e2a",
};
const url='https://tfbookfair2022.symansbon.cn';
export default {
  props: ["iptId"],
  components: {
    [Icon.name]: Icon,
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem,
    [Dialog.name]: Dialog,
  },
  data() {
    return {
      map: null,
      mouseTool: null,
      overlays: [],
      auto: null,
      placeSearch: null,
      icon: {
        // 图标类型，现阶段只支持 image 类型
        type: "image",
        // 图片 url
        image:
          "https://a.amap.com/jsapi_demos/static/demo-center/marker/express2.png",
        // 图片尺寸
        size: [64, 30],
        // 图片相对 position 的锚点，默认为 bottom-center
        anchor: "center",
      },
      lnglat: [], // 经纬度数组 [lng,lat]
      markers: [],
      driving: null,
      dizhiData: {
        type: null, //展场
        citycode: 1, //城市
      },
      option1: [
        { text: "全部", value: null },
        { text: "主展场", value: 20 },
        { text: "分展场", value: 19 },
        { text: "分会场", value: 21 },
      ],
      option2: [
        { text: "成都市", value: 1 },
        { text: "绵阳市", value: 2 },
        { text: "德阳市", value: 3 },
        { text: "眉山市", value: 4 },
        { text: "乐山市", value: 5 },
        { text: "雅安市", value: 6 },
        { text: "资阳市", value: 7 },
        { text: "自贡市", value: 8 },
        { text: "内江市", value: 9 },
        { text: "遂宁市", value: 10 },
        { text: "南充市", value: 11 },
        { text: "达州市", value: 12 },
        { text: "广安市", value: 13 },
        { text: "广元市", value: 14 },
        { text: "巴中市", value: 15 },
        { text: "宜宾市", value: 16 },
        { text: "泸州市", value: 17 },
        { text: "攀枝花市", value: 18 },
        { text: "凉山彝族自治州", value: 19 },
        { text: "阿坝藏族羌族自治州", value: 20 },
        { text: "甘孜藏族自治州", value: 21 },
      ],
      positionsList: [],
      dizhiList: false,
      diquList: [],
      topData: {},
    };
  },
  methods: {
    back() {
      this.$router.back();
    },
    //初始化地图
    initMap() {
      AMapLoader.load({
        key: "aba72bcd644a5f131588a993301cc96b", // 申请好的Web端开发者Key，首次调用 load 时必填
        version: "2.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: [
          "AMap.Autocomplete",
          "AMap.PlaceSearch",
          "AMap.Scale",
          "AMap.OverView",
          "AMap.ToolBar",
          "AMap.MapType",
          "AMap.PolyEditor",
          "AMap.CircleEditor",
          "AMap.AutoComplete",
          "AMap.Driving",
          "AMap.DragRoute",
          "AMap.Geolocation",
          "AMap.MarkerList",
        ], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
        // "plugins": [],           // 需要使用的的插件列表，如比例尺'AMap.Scale'等
      }).then((AMap) => {
        this.map = new AMap.Map("bai-du-map", {
          viewMode: "2D", //  是否为3D地图模式
          zoom: 11, // 初始化地图级别
          center: [104.066301, 30.572961], //中心点坐标  郑州
          resizeEnable: true,
          mapStyle:
            "amap://styles/2c09c3d90b5c68eb5041ec1d6707230c&jscode=d7c78951aef7b114a35b47e18ea22e2a",
        });
        this.auto = new AMap.AutoComplete({
          input: this.iptId, // 搜索框的id
        });
        this.placeSearch = new AMap.PlaceSearch({
          map: this.map,
          panel: "panel", // 结果列表将在此容器中进行展示。
          // city: "010", // 兴趣点城市
          autoFitView: true, // 是否自动调整地图视野使绘制的 Marker点都处于视口的可见范围
          extensions: "base", //返回基本地址信息
          styleName: "amap://styles/5ebd7b2276f35d524f9eab87c0b0d552",
        });
        this.getActivity();
      });
    },
    select(e) {
      this.placeSearch.setCity(e.poi.adcode);
      this.placeSearch.search(e.poi.name); //关键字查询查询
    },
    clickMapHandler(e) {
      // //console.log(e);
      this.lnglat = [e.lnglat.getLng(), e.lnglat.getLat()];
      this.setMarker(this.lnglat);
      // //console.log(123);
    },
    setMarker(lnglat) {
      // //console.log("位置", lnglat); // lnglat=[经度,纬度]
      var markerContent =
        "" +
        '<div class="custom-content-marker">' +
        '   <img src="//a.amap.com/jsapi_demos/static/demo-center/icons/dir-via-marker.png">' +
        '   <div class="close-btn" @click="clearMarker()">X</div>' +
        "</div>";
      var marker = new AMap.Marker({
        position: lnglat,
        // 将 html 传给 content
        content: markerContent,
        // 以 icon 的 [center bottom] 为原点
        offset: new AMap.Pixel(-13, -30),
      });

      marker.setMap(this.map);
      this.markers.push(marker); // 在data中记录标记点
      // this.markers.add(marker);
    },
    //删除
    removeMarker() {
      if (this.markers.length > 0) {
        this.map.remove(this.markers);
      }
    },
    zhong(arr) {
      // //console.log();
      this.removeMarker();
      var that = this;
      var positions = arr;
      // //console.log('传入的数组',positions);
      that.topData = positions[0];
      for (var i = 0, marker; i < positions.length; i++) {
        var markerContent =
          "" +
          '<div class="custom-content-marker">' +
          '   <div class="miaodian ' +
          "m" +
          positions[i].id +
          '" ><div class="zi_box">' +
          (i + 1) +
          "</div></div>" +
          "   " +
          "</div>";
        if (positions[i].isTop) {
          // //console.log(i);
          markerContent =
            "" +
            '<div class="custom-content-marker">' +
            '   <div class="miaodian_top" ><div class="zi_box_top">' +
            '<img src="http://tfbookfair2022.symansbon.cn/images/ins/top.png">' +
            "</div></div>" +
            "   " +
            "</div>";
        }
        // //console.log("获取的定位", positions[i]);
        marker = new AMap.Marker({
          content: "测试测试",
          map: that.map,
          listContainer: "myList",
          selectedClassNames: "selected",
          getDataId: function (dataItem, index) {
            //index表示该数据项在数组中的索引位置，从0开始，如果确实没有id，可以返回index代替
            return dataItem.id;
          },
          position: positions[i].latlan.split(","),
          content: markerContent,
          // icon: "//a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-default.png",
          offset: new AMap.Pixel(-13, -30),
        });

        // marker.extData('123123')
        marker.setMap(this.map);
        this.markers.push(marker);
        marker.on("click", this.markerClick, "测测测测测测测");
        marker.myData = positions[i];
      }
      var center = this.map.getCenter();
      this.map.setFitView(null, false, [80, 40, 80, 40]);
      var newCenter = this.map.getCenter();
    },
    //城市选择
    citycodeFun() {
      this.getActivity();
    },
    markerClick(e) {
      //console.log(e.target.myData);

      this.topData = e.target.myData;
      //console.log($(".m"+this.topData.id));
      $('.miaodian').removeClass('marr')
      $(".m"+this.topData.id).addClass('marr')
      let pos = this.topData.latlan.split(",");
      this.map.setCenter(pos);
      this.map.setZoom(15);
    },
    //移动定位
    ding(item) {
      this.dizhiList = false;
      //console.log(item);
      this.topData = item;
      // wx.openLocation({
      //   latitude: "104.708874", // 纬度，浮点数，范围为90 ~ -90
      //   longitude: "31.466833", // 经度，浮点数，范围为180 ~ -180。
      //   name: "[kids winshare]儿童书店(环球店)", // 位置名
      //   address: "成都市天府大道北段环球中心3F", // 地址详情说明
      //   scale: 15, // 地图缩放级别,整型值,范围从1~28。默认为最大
      //   infoUrl: "", // 在查看位置界面底部显示的超链接,可点击跳转
      // });
      $('.miaodian').removeClass('marr')
      $(".m"+this.topData.id).addClass('marr')
      this.map.setCenter(item.latlan.split(","));
      this.map.setZoom(15);
    },
    LocationFun() {
      this.getActivity();
      // //console.log(123);
    },
    getActivity() {
      this.getRequest(
        `${url}/api/Venue`,
        this.dizhiData
      ).then((res) => {
        this.zhong(res);
        this.diquList = res;
      });
    },
    openMap(item) {
      let pas = item.latlan.split(",");
      //console.log(item);
      Dialog.confirm({
        title: "提示",
        message: "是否打开微信导航？",
        beforeClose: function (action, done) {
          if (action === "confirm") {
            wx.openLocation({
              latitude: Number(pas[1]), // 纬度，浮点数，范围为90 ~ -90
              longitude: Number(pas[0]), // 经度，浮点数，范围为180 ~ -180。
              name: item.brand, // 位置名
              address: item.address, // 地址详情说明
              scale: 12, // 地图缩放级别,整型值,范围从1~28。默认为最大
              infoUrl: "", // 在查看位置界面底部显示的超链接,可点击跳转
            });
            done();
          } else {
            done();
          }
        },
      });
    },
  },
  openWxMap() {},
  mounted() {
    this.getActivity();
    this.initMap();
    this.getRequest(
      `${url}/api/WechatConfig`,
      {}
    ).then((res) => {
      //console.log("微信参数", res);
      wx.config({
        // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
        debug: false,
        // 必填，公众号的唯一标识
        appId: res.appId,
        // 必填，生成签名的时间戳
        timestamp: res.timestamp,
        // 必填，生成签名的随机串
        nonceStr: res.nonceStr,
        // 必填，签名，见附录1
        signature: res.signature,
        // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
        jsApiList: ["checkJsApi", "openLocation"],
        success(res) {
          //console.log("config返回", res);
        },
      });
    });
  },
};
</script>
 <style >
#bai-du-map {
  overflow: hidden;
  width: 100%;
  height: calc(100% - 48px - 150px - 60px);
  margin: 0;
  font-family: "微软雅黑";
}
.amap-logo {
  display: none !important;
}
/* 隐藏高德版权  */
.amap-copyright {
  display: none !important;
}

.cont {
  width: 100%;
  height: 100vh;
  overflow: hidden;
}
.custom-content-marker {
  position: relative;
  /* width: 50px;
  height: 68px; */
}

.custom-content-marker img {
  width: 100%;
  height: 100%;
}

.custom-content-marker .close-btn {
  position: absolute;
  top: -6px;
  right: -8px;
  width: 15px;
  height: 15px;
  font-size: 12px;
  background: #ccc;
  border-radius: 50%;
  color: #fff;
  text-align: center;
  line-height: 15px;
  box-shadow: -1px 1px 1px rgba(10, 10, 10, 0.2);
}

.custom-content-marker .close-btn:hover {
  background: #666;
}
.miaodian {
  width: 23px;
  height: 23px;
  background-color: #fff;
  color: #fff;
  font-size: 13px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 2px 3px 1px rgba(0, 0, 0, 0.16);
}
.miaodian_top {
  width: 74px;
  height: 74px;
  background-color: #fff;
  color: #fff;
  font-size: 13px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 2px 3px 1px rgba(0, 0, 0, 0.16);
}
.zi_box {
  width: 21px;
  height: 21px;
  background: #2a599f;
  display: flex;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 11px;
}
.zi_box_top {
  width: 62px;
  height: 62px;
  background: #2a599f;
  display: flex;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 11px;
}
.zi_box_top img {
  width: 100%;
  height: 100%;
}
.marr{
  transition: all .4s linear;
  transform: scale(2);
}
.marr .zi_box{
  background-color: #ED6C00;
}
</style>
<style scoped lang="less">
.dizhiList {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 150px;
  overflow: hidden;
  background: #f6f8fa;
  padding: 10px 28px;
  transition: all 0.4s linear;
  .title {
    position: relative;
    padding: 0 10px;
    font-size: 13px;
    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
    font-weight: 400;
    color: #888888;
    text-align: center;
    width: 100%;
    margin-bottom: 10px;
    span {
      font-size: 13px;
      margin-left: 10px;
    }
  }
  .dizhi_list_cont {
    li {
      margin-bottom: 18px;
    }
    .list_box_arr {
      border: 1px solid #2a599f;
    }
    .list_box {
      position: relative;
      width: 100%;
      height: 95px;
      background: #ffffff;
      border-radius: 6px 6px 6px 6px;
      opacity: 1;

      padding: 15px 20px;
      .list_title {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 15px;
        .name {
          font-size: 14px;
          font-family: Source Han Sans CN-Bold, Source Han Sans CN;
          font-weight: bold;
          color: #333333;
        }
        .daohang {
          font-size: 12px;
          font-family: Source Han Sans CN-Regular, Source Han Sans CN;
          font-weight: 400;
          color: #2a599f;
          span {
            font-size: 14px;
          }
        }
      }
      .text {
        font-size: 12px;
        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
        font-weight: 400;
        color: #777777;
        line-height: 1.4;
      }
    }
  }
}
.showdizhiList {
  height: 70%;
  overflow: auto;
}
</style>